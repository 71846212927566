import './base.scss';

import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import OutsideClickHandler from 'react-outside-click-handler';

import { ACTIONS, CATEGORIES, trackEvent } from '~/app/Analytics';

import CookiesBarView from './CookiesBarView';
import Footer from './Footer';
import Navbar from './Navbar';

const SCROLL_LOCKED_CLASS = 'is-scrollOverflowLocked';

export default class Layout extends Component<React.PropsWithChildren<{}>> {
  state = {
    isNavbarCollapsed: true,
  };

  handleToggleNavbar = () => {
    if (this.state.isNavbarCollapsed) {
      this.handleShowNavbar();
    } else {
      this.handleHideNavbar();
    }
    trackEvent({
      category: CATEGORIES.NAVBAR,
      action: ACTIONS.TOGGLE,
    });
  };

  handleShowNavbar = () => {
    this.setState({ isNavbarCollapsed: false });
    document.documentElement.classList.add(SCROLL_LOCKED_CLASS);
    document.body.classList.add(SCROLL_LOCKED_CLASS);
  };

  handleHideNavbar = () => {
    this.setState({ isNavbarCollapsed: true });
    document.documentElement.classList.remove(SCROLL_LOCKED_CLASS);
    document.body.classList.remove(SCROLL_LOCKED_CLASS);
  };

  render() {
    const { isNavbarCollapsed } = this.state;
    const { children } = this.props;
    return (
      <div>
        <Helmet title="Tenempleo | Empleo en Canarias" />
        <h1 className="mainTitle">Tenempleo, Empleo en Canarias</h1>
        <OutsideClickHandler onOutsideClick={this.handleHideNavbar}>
          <Navbar isNavbarCollapsed={isNavbarCollapsed} onToggleNavbar={this.handleToggleNavbar} />
        </OutsideClickHandler>
        <CookiesBarView />
        <div className="topContainer">{children}</div>
        <Footer />
      </div>
    );
  }
}
