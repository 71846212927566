import './Footer.scss';

import { Link } from 'gatsby';
import React from 'react';

import { ACTIONS, CATEGORIES, trackEvent } from '~/app/Analytics';

const buildTrackLink = (link: string) => () =>
  trackEvent({
    action: link,
    category: CATEGORIES.FOOTER,
  });

const trackAndroidLink = buildTrackLink(ACTIONS.LINK_ANDROID);
const trackFacebookLink = buildTrackLink(ACTIONS.LINK_FACEBOOK);
const trackInstagramLink = buildTrackLink(ACTIONS.LINK_INSTAGRAM);

const Footer = (): JSX.Element => (
  <footer className="footer">
    <div className="container">
      <ul className="horizontalList footer-horizontalList">
        <li className="horizontalList-item">
          <a
            href="https://play.google.com/store/apps/details?id=com.toolodev.tenempleo&utm_source=web-tenempleo"
            onClick={trackAndroidLink}
            rel="noopener noreferrer"
            target="_blank"
          >
            Play Store
          </a>
        </li>
        <li className="horizontalList-item">
          <a
            href="https://www.facebook.com/tenempleo"
            onClick={trackFacebookLink}
            rel="noopener noreferrer"
            target="_blank"
          >
            Facebook
          </a>
        </li>
        <li className="horizontalList-item">
          <a
            href="https://www.instagram.com/tenemple0"
            onClick={trackInstagramLink}
            rel="noopener noreferrer"
            target="_blank"
          >
            Instagram
          </a>
        </li>
        <li className="horizontalList-item">
          <Link to="/policy">Política de privacidad</Link>
        </li>
      </ul>
    </div>
  </footer>
);

export default Footer;
