import './Navbar.scss';

import { Link } from 'gatsby';
import React, { memo, useContext } from 'react';

import { ACTIONS, CATEGORIES, trackEvent } from '~/app/Analytics';
import { TweetContext } from '~/app/TweetContext';
import { NavbarSearch } from '~/components/NavbarSearch';

import FacebookSvg from '../img/facebook.svg';
import TextOnlyLogoSvg from '../img/ic_logo_textonly.svg';
import InstagramSvg from '../img/instagram.svg';
import MenuSvg from '../img/menu.svg';

const buildTrackLink = (link: string) => () =>
  trackEvent({
    action: link,
    category: CATEGORIES.NAVBAR,
  });

const trackAndroidLink = buildTrackLink(ACTIONS.LINK_ANDROID);
const trackFacebookLink = buildTrackLink(ACTIONS.LINK_FACEBOOK);
const trackInstagramLink = buildTrackLink(ACTIONS.LINK_INSTAGRAM);

interface NavbarProps {
  isNavbarCollapsed: boolean;
  onToggleNavbar(): void;
}

/**
 * Renders the navbar and links
 */
const Navbar = memo(function Navbar({
  isNavbarCollapsed,
  onToggleNavbar,
}: NavbarProps): JSX.Element {
  const { setFilter } = useContext(TweetContext);
  return (
    <>
      <nav className="navbar">
        <div className="navbar-header">
          <div className="container">
            <div className="navbar-left">
              <button className="navbar-toggle" onClick={onToggleNavbar}>
                <MenuSvg className="navbar-svg" />
              </button>
              <Link to="/" className="navbar-brand">
                <TextOnlyLogoSvg className="navbar-logo" />
              </Link>
              <NavbarSearch onSearch={setFilter} />
            </div>
            <div className="navbar-right">
              <a
                className="navbar-link"
                href="https://www.instagram.com/tenemple0"
                onClick={trackInstagramLink}
                rel="noopener noreferrer"
                target="_blank"
              >
                <InstagramSvg className="navbar-svg" />
              </a>
              <a
                className="navbar-link"
                href="https://www.facebook.com/tenempleo"
                onClick={trackFacebookLink}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FacebookSvg className="navbar-svg" />
              </a>
              <a
                className="navbar-link"
                href="https://play.google.com/store/apps/details?id=com.toolodev.tenempleo&utm_source=web-tenempleo"
                onClick={trackAndroidLink}
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  alt="Disponible en Google Play"
                  className="navbar-googlePlayImage"
                  src="https://play.google.com/intl/en_gb/badges/images/generic/es_badge_web_generic.png"
                />
              </a>
            </div>
          </div>
        </div>
        {!isNavbarCollapsed && (
          <div className="navbar-body">
            <div className="container">
              <div className="navbar-mobile">
                <a
                  className="navbar-link"
                  href="https://www.instagram.com/tenemple0"
                  onClick={trackInstagramLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <InstagramSvg className="navbar-svg" />
                </a>
                <a
                  className="navbar-link"
                  href="https://www.facebook.com/tenempleo"
                  onClick={trackFacebookLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FacebookSvg className="navbar-svg" />
                </a>
                <a
                  className="navbar-link"
                  href="https://play.google.com/store/apps/details?id=com.toolodev.tenempleo&utm_source=web-tenempleo"
                  onClick={trackAndroidLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    alt="Disponible en Google Play"
                    className="navbar-googlePlayImage"
                    src="https://play.google.com/intl/en_gb/badges/images/generic/es_badge_web_generic.png"
                  />
                </a>
              </div>
            </div>
          </div>
        )}
      </nav>
      <div className="navbar-paddingPlaceholder" />
    </>
  );
});

export default Navbar;
