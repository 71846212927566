import './Button.scss';

import cx from 'classnames';
import React from 'react';

interface ButtonProps {
  className?: string;
  onClick?(): void;
  title?: string;
  type?: 'submit' | 'button';
}

const Button = ({
  children,
  className,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick = () => {},
  title,
  type = 'button',
}: React.PropsWithChildren<ButtonProps>): JSX.Element => {
  return (
    <button className={cx('button', className)} onClick={onClick} title={title} type={type}>
      {children}
    </button>
  );
};

export default Button;
