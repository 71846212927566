import './CookiesBar.scss';

import React from 'react';

import Button from './Button';

interface CookiesBarProps {
  onClose(): void;
}

const CookiesBar = ({ onClose }: CookiesBarProps): JSX.Element => (
  <div className="cookiesBar">
    <p className="cookiesBar-text">
      Utilizamos cookies de terceros para elaborar información estadística y mejorar tu experiencia
      de usuario. Si continúas navegando, aceptas su uso.
    </p>
    <Button className="cookiesBar-button" onClick={onClose} type="button">
      Aceptar
    </Button>
  </div>
);

export default CookiesBar;
