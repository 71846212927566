import window from 'global/window';

export const TWITTER_HANDLE = 'tenempleo';

export const ORIGIN = window.origin || 'http://tenempleo.com';
export const LOCATION_TO_TRANSLATION_MAP = {
  canarias: 'Canarias',
  elhierro: 'El Hierro',
  fuerteventura: 'Fuerteventura',
  grancanaria: 'Gran Canaria',
  lagomera: 'La Gomera',
  lanzarote: 'Lanzarote',
  lapalma: 'La Palma',
  tenerife: 'Tenerife',
};
export const TRANSLATION_TO_LOCATION_MAP: {
  [k: string]: TweetLocation;
} = Object.entries(LOCATION_TO_TRANSLATION_MAP).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [value]: key,
  }),
  {}
);

export type TweetLocation = keyof typeof LOCATION_TO_TRANSLATION_MAP;
