import './NavbarSearch.scss';

import cx from 'classnames';
import React, { ComponentType, memo, useCallback, useRef, useState } from 'react';

import {
  LOCATION_TO_TRANSLATION_MAP,
  TRANSLATION_TO_LOCATION_MAP,
  TweetLocation,
} from '~/app/Constants';
import Lens from '~/img/ic_lens.svg';

/**
 * Managed key codes
 */
const KEY_CODE = {
  DOWN: 40,
  ENTER: 14,
  ESCAPE: 27,
  RETURN: 13,
  UP: 38,
};

interface NavbarSearchProps {
  onSearch(location: TweetLocation): void;
}

const ALL_LOCATIONS = Object.entries(LOCATION_TO_TRANSLATION_MAP);

/**
 * Navbar search component
 */
export const NavbarSearch: ComponentType<NavbarSearchProps> = memo(function NavbarSearch({
  onSearch,
}: NavbarSearchProps): JSX.Element {
  const [value, setValue] = useState('');
  const [locations, setLocations] = useState(ALL_LOCATIONS);
  const [isFocused, setIsFocused] = useState(false);
  const [focusedSuggestion, setFocusedSuggestion] = useState(-1);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isAutocompleteHovered, setIsAutocompleteHovered] = useState(false);
  const isExpanded = isAutocompleteHovered || isFocused;

  /**
   * Updates the component when the value changes
   */
  const handleChange = useCallback((newValue: string): void => {
    const filteredLocations = ALL_LOCATIONS.filter(([, translation]) =>
      translation.toLowerCase().match(newValue.toLowerCase())
    );
    setValue(newValue);
    setLocations(filteredLocations);
    if (filteredLocations.length === 1) {
      setFocusedSuggestion(0);
    } else {
      setFocusedSuggestion(-1);
    }
  }, []);

  /**
   * Updates the component before searching and calls the onSearch prop
   * @param location location key
   * @param [translation] location translation value
   */
  const handleSearch = useCallback(
    (location: string, translation?: string) => {
      onSearch(location as TweetLocation);
      inputRef.current?.blur();
      if (translation) {
        setValue(translation);
        setLocations(ALL_LOCATIONS);
      }
      setIsAutocompleteHovered(false);
      setFocusedSuggestion(-1);
    },
    [onSearch]
  );

  /**
   * Manages keyUp events for DOWN, UP, ESCAPE and ENTER/RETURN keys.
   * - UP/DOWN updates the focusedSuggestion
   * - ESCAPE closes the autocomplete
   * - ENTER/RETURN performs a search if a valid location is selected/written
   */
  const handleKeyUp = useCallback(
    (event: React.KeyboardEvent): void => {
      const locationsLength = locations.length;
      switch (event.keyCode) {
        case KEY_CODE.DOWN:
          setFocusedSuggestion((focusedSuggestion + 1) % locationsLength);
          break;
        case KEY_CODE.UP:
          setFocusedSuggestion(Math.max((focusedSuggestion - 1) % locationsLength, -1));
          break;
        case KEY_CODE.ESCAPE:
          setFocusedSuggestion(-1);
          inputRef.current?.blur();
          break;
        case KEY_CODE.RETURN:
        case KEY_CODE.ENTER: {
          if (focusedSuggestion !== -1) {
            return handleSearch(...locations[focusedSuggestion]);
          }

          const maybeValidLocation = TRANSLATION_TO_LOCATION_MAP[value];
          if (maybeValidLocation) {
            handleSearch(maybeValidLocation);
          }
          break;
        }
      }
    },
    [focusedSuggestion, handleSearch, locations, value]
  );

  return (
    <div
      aria-expanded={isExpanded}
      aria-haspopup="listbox"
      aria-owns="navbarSearch-listbox"
      className="navbarSearch"
      role="combobox"
    >
      <label className="navbarSearch-label" htmlFor="navbarSearch-input" id="navbarSearch-label">
        Filtro por isla
      </label>
      <div className="navbarSearch-inputWrapper">
        <Lens aria-hidden={true} className="navbarSearch-icon" />
        <input
          aria-activedescendant={focusedSuggestion !== -1 ? locations[focusedSuggestion][0] : ''}
          aria-autocomplete="list"
          aria-controls="navbarSearch-listbox"
          autoComplete="off"
          className="navbarSearch-input"
          id="navbarSearch-input"
          onBlur={(): void => setIsFocused(false)}
          onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
            handleChange(event.target.value)
          }
          onFocus={(): void => setIsFocused(true)}
          onKeyUp={handleKeyUp}
          placeholder="Filtrar por isla..."
          ref={inputRef}
          type="text"
          value={value}
        />
      </div>
      <ul
        aria-labelledby="navbarSearch-label"
        className="navbarSearch-autocomplete"
        onMouseEnter={(): void => setIsAutocompleteHovered(true)}
        onMouseLeave={(): void => setIsAutocompleteHovered(false)}
        role="listbox"
      >
        {isExpanded &&
          locations.map(([location, translation], index) => (
            <li
              aria-selected={index === focusedSuggestion}
              key={location}
              className={cx('navbarSearch-suggestion', {
                'is-selected': index === focusedSuggestion,
              })}
              id={location}
              onClick={(): void => handleSearch(location, translation)}
              role="option"
            >
              {translation}
            </li>
          ))}
      </ul>
    </div>
  );
});
