import window from 'global/window';

const getAnalytics = () => window.ga || console.log;

export const CATEGORIES = {
  FOOTER: 'footer',
  NAVBAR: 'navbar',
  OFFER: 'offer',
};

export const ACTIONS = {
  EMAIL: 'email',
  LINK: 'link',
  LINK_ANDROID: 'link_android',
  LINK_FACEBOOK: 'link_facebook',
  LINK_INSTAGRAM: 'link_instagram',
  PHONE: 'phone',
  SHARE: 'share',
  TOGGLE: 'toggle',
};

export const trackEvent = ({
  action,
  category,
  label,
  value,
}: {
  action: string;
  category: string;
  label?: string;
  value?: string;
}) => getAnalytics()('send', 'event', category, action, label, value);
