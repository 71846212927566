import { createContext } from 'react';

import { TweetLocation } from '~/app/Constants';
import { Tweet } from '~/app/tweetTypes';

export interface TweetState {
  error?: string;
  filter?: TweetLocation;
  isLoading: boolean;
  setFilter(filter: TweetLocation): void;
  tweets?: Tweet[];
}

/**
 * Global app state
 */
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const TweetContext = createContext<TweetState>({ isLoading: false, setFilter(_) {} });
