import window from 'global/window';
import React, { PureComponent } from 'react';

import CookiesBar from './CookiesBar';

const COOKIES_BAR_HIDDEN = 'COOKIES_BAR_HIDDEN';

export default class CookiesBarView extends PureComponent {
  state = {
    hideCookiesBar: true,
  };

  timeout: number;

  componentDidMount() {
    this.timeout = window.setTimeout(() => {
      this.setState({
        hideCookiesBar: window.localStorage && window.localStorage.getItem(COOKIES_BAR_HIDDEN),
      });
    }, 250);
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeout);
  }

  handleCloseCookiesBar = () => {
    this.setState({ hideCookiesBar: true });
    window.localStorage.setItem(COOKIES_BAR_HIDDEN, 'hidden');
  };

  render() {
    if (this.state.hideCookiesBar) {
      return null;
    }

    return <CookiesBar onClose={this.handleCloseCookiesBar} />;
  }
}
